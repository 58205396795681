import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import "../styles/prism.css"
import "./prism.css"

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      bodyAttributes={{
        class: "h-screen",
      }}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `Expires`,
          content: "Mon, 26 Jul 1997 05:00:00 GMT",
        },
        {
          name: `Cache-Control`,
          content: "no-cache, no-store, must-revalidate",
        },
        {
          name: `Pragma`,
          content: "no-cache",
        },
        // {
        //   name: `Expires`,
        //   content: "0",
        // },
        {
          name: "google-site-verification",
          conten: "tO-9zYz8izZRllccbN6L2AinGhJlOy3RUmhBRFU6v4g",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link href={withPrefix("css/tailwind.min.css")} rel="stylesheet"></link>

      <script
        src={withPrefix("js/code.jquery.com_jquery-1.12.4.min.js")}
      ></script>
      {/* <script src="https://cloud.issabel.org/click2call/click.js?code=eyJjdCI6Im1TalZKS3pCWjBHbHBiRlYrRnJxa3htaXJ4cDdZS1lOa3ZzNjlXa1ZrZWF3N21lc1lYVVhOZ3owUk1IUGJiU1RIbFUrT0I4S05JZmdTM3kyeHhPZDVLZEpVNk5TQTljb1lTb05xZ0FKM0dOTTZ0d3B4XC9zelZLNlNPdEQzczVGSzliUGYyTEVOTVdKQmJwR2tYeDh3cWJQXC9zaFNiNVZJbGFJZnM0MnM1QTBnZUpJQ005bEdmTDJncnVoQk91SjZqWmtPaDg1WEZyVUtLQzgrcFwvelpSKzVZS01WMXF3MXJKbUlROGs4RllaXC9OK0s3b0lMNkhxcmVtTDRWVytmbCtvRUtnRVJYUTFJMmQ4V3JYUExrXC9qd01NRnZvQjY1eG56YTRzWjlESVwveWpWd0FNXC9ZWjg3NlV5NHRFTmhnaVcwVSIsIml2IjoiYjk4YTdjN2UyYjk4ODQxNGJmMWQzMjkzODg5N2Y2NTEiLCJzIjoiMzdjZjU1NzNkYmI5NWM5ZSJ9">
      </script>
     */}
      <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/44593063.js"></script>
      <script src={withPrefix("js/clean_cache.js")}></script>

      <script src={withPrefix("js/paginador.js")}></script>
      <script src={withPrefix("js/boton_flotante.js")} type="text/javascript" />
      <script src={withPrefix("js/modal_popup.js")}></script>

      <script src={withPrefix("js/tamano_letra.js")}></script>
      <script src={withPrefix("js/fondo_contraste.js")}></script>
      <script src={withPrefix("js/mostrar_noticia.js")}></script>
      <script src={withPrefix("js/acordeon.js")}></script>

      <script src={withPrefix("js/search.js")} type="text/javascript" />
      <script src={withPrefix("js/cookies.js")} type="text/javascript" />
      <script
        src={withPrefix("js/desplegar_contenido.js")}
        type="text/javascript"
      />
      <script
        src={withPrefix("js/menu_responsive.js")}
        type="text/javascript"
      />

      <script
        src={withPrefix("js/footer_responsive.js")}
        type="text/javascript"
      />

      <script src={withPrefix("js/prism.js")} type="text/javascript" />

      <script
        src="https://www.google.com/recaptcha/api.js"
        async
        defer
      ></script>
      <script src="https://www.google.com/recaptcha/api.js?hl=es"></script>
      <script
        src="https://kit.fontawesome.com/eb496ab1a0.js"
        crossorigin="anonymous"
      ></script>
      <script src={withPrefix("js/google_tab.js")}></script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
